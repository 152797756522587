import { template as template_aa8e4427e7c14d50af1ef9c03610aa2d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_aa8e4427e7c14d50af1ef9c03610aa2d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
