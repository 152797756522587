import { template as template_2debc5b101c946fc86e7be928d007a88 } from "@ember/template-compiler";
const FKText = template_2debc5b101c946fc86e7be928d007a88(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
