import { template as template_9f85964eccb54345a9432df66513671e } from "@ember/template-compiler";
const FKLabel = template_9f85964eccb54345a9432df66513671e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
