import { template as template_a37c7ac053df4755bb598ea1e7f47bd8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a37c7ac053df4755bb598ea1e7f47bd8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
