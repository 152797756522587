import { template as template_998470f34f044f749e22a15943eeefb3 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import DSelect, { DSelectOption } from "discourse/components/d-select";
const SelectOption = template_998470f34f044f749e22a15943eeefb3(`
  <DSelectOption
    @value={{@value}}
    @selected={{@selected}}
    class="form-kit__control-option"
  >
    {{yield}}
  </DSelectOption>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class FKControlSelect extends Component {
    static controlType = "select";
    get includeNone() {
        return this.args.field.validation !== "required";
    }
    static{
        template_998470f34f044f749e22a15943eeefb3(`
    <DSelect
      class="form-kit__control-select"
      disabled={{@field.disabled}}
      @value={{@field.value}}
      @onChange={{@field.set}}
      @includeNone={{this.includeNone}}
      ...attributes
    >
      {{yield (hash Option=(component SelectOption selected=@field.value))}}
    </DSelect>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
